/*
 ! ----------------------------------------- DISCLAIMER -----------------------------------------------
 * In the below color and theme definitions light and dark refer to the theme's available.
 * In order to avoid confusions arising from the same naming used for multiple purposes
 * the darker and lighter versions of the themes' primary and accent colors are referred to as "STRONG" and "WEAK" respectively.
*/

@use '@angular/material' as mat;

@include mat.core();

// ? Theme wrapper? handling transition on theme change
.theme-wrapper {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

// * -------------------------- PALETTES --------------------------------

$ukr-blue-palette: (
    50: var(--ukr-blue-50),
    100: var(--ukr-blue-100),
    200: var(--ukr-blue-200),
    300: var(--ukr-blue-300),
    400: var(--ukr-blue-400),
    500:var(--ukr-blue-500),
    600:var(--ukr-blue-600),
    700:var(--ukr-blue-700),
    800:var(--ukr-blue-800),
    900:var(--ukr-blue-900),
    contrast: (
        50: var(--black),
        100: var(--black),
        200: var(--black),
        300: var(--black),
        400: var(--black),
        500: var(--black),
        600: var(--black),
        700: var(--black),
        800: var(--black),
        900:  var(--white)
    )
);


$ukr-yellow-palette: (
    50: var(--ukr-yellow-50),
    100: var(--ukr-yellow-100),
    200: var(--ukr-yellow-200),
    300: var(--ukr-yellow-300),
    400: var(--ukr-yellow-400),
    500:var(--ukr-yellow-500),
    600:var(--ukr-yellow-600),
    700:var(--ukr-yellow-700),
    800:var(--ukr-yellow-800),
    900:var(--ukr-yellow-900),
    contrast: (
        50: var(--black),
        100: var(--black),
        200: var(--black),
        300: var(--black),
        400: var(--black),
        500: var(--black),
        600: var(--black),
        700: var(--black),
        800: var(--black),
        900: var(--black),
    )
);


// * ---------------------------- HELPERS ----------------------------------------------

$lighter-blue-hue: 100;
$light-blue-hue: 500;
$normal-blue-hue: 700;
$dark-blue-hue: 900;

$lighter-yellow-hue: 100;
$light-yellow-hue: 200;
$normal-yellow-hue: 600;
$dark-yellow-hue: 700;

$bright-warn-hue: 300;

// * ----------------------------------- PALETTES --------------------------------------

$blue-palette: mat.define-palette($ukr-blue-palette, $normal-blue-hue, $light-blue-hue, $dark-blue-hue);
$yellow-palette: mat.define-palette($ukr-yellow-palette, $normal-yellow-hue, $light-yellow-hue, $dark-yellow-hue);
$warn-palette: mat.define-palette(mat.$red-palette);

// * ----------------------- MAT-THEME-COLORS ------------------------------------------

// ? colors for light theme
$primary: $blue-palette;
$accent: $yellow-palette;
$warn: $warn-palette;

// ? colors for dark theme
$dark-primary: $yellow-palette;
$dark-accent: $blue-palette;
$dark-warn: $warn-palette;

// * ------------------------------ THEME DEFINITIONS -----------------------------------

.light-theme {
    $theme: mat.define-light-theme(
        (
            color: (
                primary: $primary,
                accent: $accent,
                warn: $warn,
            ),
        )
    );

    @include mat.all-component-themes($theme);
}

.dark-theme {
    $dark-theme: mat.define-dark-theme(
        (
            color: (
                primary: $dark-primary,
                accent: $dark-accent,
                warn: $dark-warn,
            ),
        )
    );

    @include mat.all-component-themes($dark-theme);
}

@mixin lightTheme {
    --lighter-primary: #{mat.get-color-from-palette($primary, $lighter-blue-hue)};
    --weak-primary: #{mat.get-color-from-palette($primary, $light-blue-hue)};
    --primary: #{mat.get-color-from-palette($primary)};
    --strong-primary: #{mat.get-color-from-palette($primary, $dark-blue-hue)};

    --weak-accent: #{mat.get-color-from-palette($accent, $light-yellow-hue)};
    --accent: #{mat.get-color-from-palette($accent)};
    --strong-accent: #{mat.get-color-from-palette($accent, $dark-yellow-hue)};

    --warn: #{mat.get-color-from-palette($warn)};
    --warn-bright: #{mat.get-color-from-palette($warn, $bright-warn-hue)};

    --bg-color-main: var(--white);
    --bg-color-shade: var(--light-white);
    --bg-color-accent: var(--dark-white);
    --bg-color-warn: var(--warn-bright);

    --color: var(--black);
}

@mixin darkTheme {
    --lighter-primary: #{mat.get-color-from-palette($accent, $lighter-yellow-hue)};
    --weak-primary: #{mat.get-color-from-palette($accent, $light-yellow-hue)};
    --primary: #{mat.get-color-from-palette($dark-primary)};
    --strong-primary: #{mat.get-color-from-palette($accent, $dark-yellow-hue)};

    --weak-accent: #{mat.get-color-from-palette($primary, $light-blue-hue)};
    --accent: #{mat.get-color-from-palette($dark-accent)};
    --strong-accent: #{mat.get-color-from-palette($primary, $dark-blue-hue)};

    --warn: #{mat.get-color-from-palette($dark-warn)};
    --warn-bright: #{mat.get-color-from-palette($warn, $bright-warn-hue)};

    --bg-color-main: var(--dark-black);
    --bg-color-shade: var(--light-black);
    --bg-color-accent: var(--black);
    --bg-color-warn: var(--warn-bright);

    --color: var(--light-white);
}

:root {
    & .light-theme {
        @include lightTheme();

        --root-lighter-primary: var(--lighter-primary);
        --root-weak-primary: var(--weak-primary);
        --root-primary: var(--primary);
        --root-strong-primary: var(--strong-primary);

        --root-weak-accent: var(--weak-accent);
        --root-accent: var(--accent);
        --root-strong-accent: var(--strong-accent);

        --root-warn: var(--warn);

        --root-bg-color-main: var(--bg-color-main);
        --root-bg-color-shade: var(--bg-color-shade);
        --root-bg-color-accent: var(--bg-color-accent);
        --root-bg-color-warn: var(--bg-color-warn);

        --root-color: var(--color);
    }

    & .dark-theme {
        @include darkTheme();

        --root-lighter-primary: var(--lighter-primary) ;
        --root-weak-primary: var(--weak-primary);
        --root-primary: var(--primary);
        --root-strong-primary: var(--strong-primary);

        --root-weak-accent: var(--weak-accent);
        --root-accent: var(--accent);
        --root-strong-accent: var(--strong-accent);

        --root-warn: var(--warn);

        --root-bg-color-main: var(--bg-color-main);
        --root-bg-color-shade: var(--bg-color-shade);
        --root-bg-color-accent: var(--bg-color-accent);
        --root-bg-color-warn: var(--bg-color-warn);

        --root-color: var(--color);
    }
}
