.is-active {
    background: var(--green) !important;
}

.own {
    background: var(--root-primary) !important;
}

.not-allowed {
    text-decoration: line-through;
    opacity: 0.6;
}

.btn-no-result {
    background: var(--ukr-blue-900) !important;
    padding: 10px 5px;
    width: 100px;
    border: none;
    color: var(--root-bg-color-main);
}

.icon-deny {
    color: var(--grey) !important;
    opacity: 0.55;
}


.primary-text {
    color: var(--root-primary) !important;
}

.themed-text {
    color: var(--root-color) !important;
}

.bg-color-text {
    color: var(--root-bg-color-main) !important;
}

.primary-bg {
    background-color: var(--root-primary) !important;
}

.themed-bg {
    background-color: var(--root-bg-color-main) !important;
}

.alternate-bg {
    background-color: var(--root-bg-color-shade) !important;
}
