:root {
    --light-black: #262626;
    --black: #1C1C1C;
    --dark-black: #121212;

    --light-white: #FFFFFF;
    --white: #EBEBEB;
    --dark-white: #D6D6D6;

    --grey: #b3b0b0;
    --green: #46b478;

    // ? UKR blue
    --ukr-blue-50: #e1f6ff;
    --ukr-blue-100: #b2e6ff;
    --ukr-blue-200: #7dd7ff;
    --ukr-blue-300: #3ec7ff;
    --ukr-blue-400: #00baff;
    --ukr-blue-500:#00acff;
    --ukr-blue-600:#009eff;
    --ukr-blue-700:#008aec;
    --ukr-blue-800:#0079d7;
    --ukr-blue-900:#0057b5;

    // ? UKR yellow
    --ukr-yellow-50: #fefce4;
    --ukr-yellow-100: #fdf5bd;
    --ukr-yellow-200: #fbef92;
    --ukr-yellow-300: #f9e965;
    --ukr-yellow-400: #f8e43f;
    --ukr-yellow-500:#f5de01;
    --ukr-yellow-600:#f6cd00;
    --ukr-yellow-700:#f5b500;
    --ukr-yellow-800:#f49c00;
    --ukr-yellow-900:#f27000;
}
