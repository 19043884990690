// Own variables.
@import './assets/scss/variables';
// Imports functions, variables, and mixins that are needed by other Bootstrap files.
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/root'; // Contains :root CSS variables used by other Bootstrap files
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/utilities/api'; // Generates the actual utility classes
// Reset scss for overwrite any library or user agent style.
@import './assets/scss/reset';
// mapbox styles
@import '~mapbox-gl/dist/mapbox-gl.css';
// Own utilities
@import './assets/scss/utilities';
// Own global styles
@import './assets/scss/spinner';
@import './assets/scss/common.scss';
// ? App Theme
@import './assets/scss/material-theme.scss';
// ? Color defs
@import './assets/scss/colors.scss';

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

// .dark-theme.mat-app-background {
//     background-color: var(--black) !important;
// }

// .light-theme.mat-app-background {
//     background-color: var(--dark-white) !important;
// }

.mat-app-background {
    background-color: var(--root-bg-color-main) !important;
}

.mat-sidenav-content {
    overflow-y:overlay !important;
}

.warning-container {
    border-radius: 5px;
    background: var(--root-bg-color-warn);
    color: var(--white);
}

.info-container {
    border-radius: 5px;
    background-color: var(--root-lighter-primary);
    color: var(--black);
}

.mat-flat-button {
    color: var(--root-bg-color-shade) !important;
}

a {
    &.mat-button,
    &.mat-raised-button,
    &.mat-flat-button,
    &.mat-fab,
    &.mat-mini-fab,
    &.mat-list-item {
        &:hover {
            color: currentColor;
        }
    }
    text-decoration: none;
}

.form mat-slide-toggle {
    margin: 8px 0;
    display: block;
}

mat-slide-toggle {
    label.mat-slide-toggle-label .mat-slide-toggle-bar {
        background-color: var(--root-weak-primary) !important;
    }

    &:not(.mat-checked) {
        .mat-slide-toggle-thumb,
        label.mat-slide-toggle-label .mat-slide-toggle-bar {
           filter: saturate(0.35);
        }
    }
    .mat-slide-toggle-thumb {
        background-color: var(--root-primary) !important;
    }
}

.shelter-card, .transfer-card {
    mat-card {
        border-radius: 10px;
    }
}


.user-avatar-image {
    height: 17px;
    width: 17px;
    border-radius: 8.5px;
}

.background-input {
    /* recolor mat-input */
    .mat-form-field-appearance-outline .mat-form-field-outline {
        background-color: var(--root-bg-color-shade) !important;
        border-radius: 5px;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }
}

.background-mat-select {
    .mat-form-field-flex {
        background-color: var(--root-bg-color-main) !important;
    }
}

app-my-shelters-page {
    padding-bottom: 50px;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: var(--root-strong-primary);
    border-radius: 4px;
}
