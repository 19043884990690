// The bootstrap property overwrite.
$link-color: #3f51b5;
$link-hover-color: currentColor;
$link-hover-decoration: none;
$label-margin-bottom: 0;

$secondary: grey;

// Set bootstrap grid to material breakpoints.
$grid-breakpoints: (
    // handset portrait (small, medium, large) | handset landscape (small)
    xs: 0,
    // handset landscape (medium, large) | tablet portrait(small, large)
    sm: 600px,
    //  tablet landscape (small, large)
    md: 960px,
    // laptops and desktops
    lg: 1280px,
    // large desktops
    xl: 1600px
);

// Set bootstrap container breakpoints to material breakpoints.
$container-max-widths: (
    sm: 600px,
    md: 960px,
    lg: 1280px,
    xl: 1600px,
);
